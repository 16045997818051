import { defineStore, storeToRefs } from 'pinia'
import { timeRangeStore } from './time-range-store'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import {
  BuzzSentimentApiFactory,
  EntityType,
  Message,
  PersonV6,
  QuotesForTitlesQuotesInner,
  SearchMessagesStatistics,
  TitleInformation
} from '@stockpulse/typescript-axios'
import { AsyncData } from 'components/models'
import { sub } from 'date-fns'
import { sourceFilterStore } from 'stores/source-filter-store'
import {
  api,
  axiosRequestManager,
  extractReferencedPeople,
  fetchChartData,
  fetchInfosForTitles,
  fetchQuoteChartData,
  fetchSolrMessages,
  fetchStatistics,
  fetchSummary,
  fetchTitleChartData,
  fetchTopicsList,
  MessageFilter,
  SolrTitleFilters,
  ViewFilter
} from 'boot/axios'
import { webSocketStore } from 'stores/web-socket-store'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { ROUTE_NAME_VIEW } from 'src/router/routes'
import { KeyEventsV2ForTitles } from '@stockpulse/typescript-axios/types'
import { KeyEventsV2ApiFactory } from '@stockpulse/typescript-axios/dist/client/key-events-v2-api'
import {
  Auxiliary,
  BuzzSentimentTitlesTableRow,
  TitlesBuzzSentimentDataCollection,
  TreeDataSet
} from 'src/helper/Auxiliary'
import * as StringHelper from 'src/helper/StringHelper'
import { getEntityType } from 'src/helper/StringHelper'
import ThrottlingMessageProcessor from 'src/services/ThrottlingMessageProcessor'
import { SubscriptionId, useRealTimeDataStore } from 'stores/realtime-data-store'
import { useBufferedThrottleFn } from 'src/helper/BufferedThrottle'
import { DEFAULT_REPORT_MODELS, getDefaultReportModel, ReportModel } from 'src/helper/ReportHelper'
import { prepareMessageHistoryChartData, StackItem } from 'src/helper/DataTransformer'
import { AxiosRequestConfig } from 'axios/index'
import { useCommonStore } from 'stores/common-store'

export interface Title {
  name: string,
  id: number,
  type: EntityType,
  buzz: number,
  buzz_open: number,
  currency?: string
}

export enum ListEntityType {
  LIST = 'list',
  SECTOR = 'sector',
  WATCHLIST = 'watchlist',
  EXCHANGE = 'exchange',
  REGION = 'region',
  COUNTRY = 'country',
  ASSET = 'asset',
  KEYEVENT = 'keyEvent',
  TYPE = 'type'
}

export interface TitleChartData {
  titleId: number,
  titleName: string,
  chartDataSeries: (number | string)[][],
  minValue: number,
  maxValue: number,
  lastQuote: QuotesForTitlesQuotesInner,
  secondLastQuote: QuotesForTitlesQuotesInner,
  currency: string
}

export interface EntityInformation {
  name: string
}

export const useListViewStore = defineStore('listView', () => {
  const timeRange = timeRangeStore()
  const sourceFilter = sourceFilterStore()
  const realtimeDataStore = useRealTimeDataStore()
  const { titlesRealtimeData } = storeToRefs(realtimeDataStore)
  const buzzSentimentAPI = BuzzSentimentApiFactory(undefined, '/v6', api)

  const commonStore = useCommonStore()
  const { titlesInfo } = storeToRefs(commonStore)

  const webSocket = webSocketStore()
  const router = useRouter()
  const notificationService = new NotificationService(router)

  const reloadAllHash = ref('')
  const reloadMessagesHash = ref('')

  // Store Properties
  const entityType: Ref<string|undefined> = ref()

  const entityInformation = ref<AsyncData<EntityInformation>>({ loading: true })

  const titleFilter = ref<ViewFilter>()
  const messageFilter : Ref<MessageFilter> = ref({
    messageType: [],
    messageLanguage: [],
    authorType: [],
    keyEvent: [],
    asset: []
  })

  const searchQuery = ref<string>('')
  const titles : Ref<AsyncData<Title[]>> = ref({ loading: true, value: [] })

  const keyEventsForTitles = ref<AsyncData<KeyEventsV2ForTitles>>({ loading: true })

  const messageStatistics = ref<AsyncData<SearchMessagesStatistics>>({ loading: true, value: undefined })
  const messages = ref<AsyncData<Message[]>>({ loading: true, value: [] })
  const keyEventsV2ApiFactory = KeyEventsV2ApiFactory(undefined, '/v6', api)

  // List only
  const indexTitleInformation: ComputedRef<AsyncData<Array<TitleInformation>>> = computed(() => {
    if (entityType.value !== ListEntityType.LIST || titleFilter.value?.list === undefined) {
      return { loading: false }
    }

    const titleIds = titleFilter.value?.list

    if (titleIds.some(titleId => titlesInfo.value[titleId]?.loading)) {
      return { loading: true }
    }

    return {
      loading: false,
      value: titleIds
        .map(titleId => titlesInfo.value[titleId]?.value)
        .filter((titleInfo: TitleInformation | undefined): titleInfo is TitleInformation => titleInfo !== undefined)
    }
  })
  const indexChartData = ref<AsyncData<{ history: Array<{ t:number, Buzz:number, Sentiment:number }>, reportModel: ReportModel }>>({ loading: true, value: { history: [], reportModel: DEFAULT_REPORT_MODELS.daily } })
  const indexQuoteChartData = ref<AsyncData<{ history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel }>>({ loading: true, value: { history: [], reportModel: DEFAULT_REPORT_MODELS.daily } })

  // Websocket
  const wsMessageListeners = ref<CallableFunction[]>([])
  const subscriptionIds: Ref<SubscriptionId[]> = ref([])
  const historicBuzzSentiment = ref<AsyncData<TitlesBuzzSentimentDataCollection>>({ loading: true, value: [] })

  const topicsList = ref<AsyncData<string[]>>({ loading: true, value: [] })
  const executiveSummary = ref<AsyncData<{ summary: string, messages: Array<Message> }>>({
    loading: true,
    value: { summary: '', messages: [] }
  })
  const referencedPeople = ref<AsyncData<{ label: string, value: number }[]>>({ loading: true, value: [] })
  const referencedPeopleInfos = ref<AsyncData<PersonV6[]>>({ loading: true, value: [] })
  const top5ChartData = ref<AsyncData<TitleChartData[]>>({ loading: true, value: [] })

  watch(
    () => messageFilter,
    () => {
      loadData()
    }, { deep: true }
  )

  async function setFilter (list: number[], exchange: number[], sector: number[], region: string[], country: string[], keyEvent: number[], asset: number[], type: EntityType[], aSearchQuery: string): Promise<void> {
    titleFilter.value = {
      list,
      exchange,
      sector,
      region,
      country,
      keyEvent,
      asset,
      type
    }
    searchQuery.value = aSearchQuery
    await loadData()
  }

  const titlesTreeData = computed<AsyncData<TreeDataSet[]>>(() => {
    if (!titles.value.value || titles.value.loading) {
      return { loading: true, value: [] }
    }

    if (!timeRange.isTimeSpanSelected() && historicBuzzSentiment.value.loading) {
      return { loading: true, value: [] }
    }

    // We do not want to use the ref of titles!
    const titlesForBuzzSentiment = Array.from(titles.value.value)

    if (timeRange.isTimeSpanSelected()) {
      return {
        loading: false,
        value: Auxiliary.createTreeDataFromTitlesBuzzSentiment(titlesForBuzzSentiment, titlesRealtimeData.value)
      }
    } else {
      if (historicBuzzSentiment.value.value === undefined) {
        return {
          loading: false,
          value: []
        }
      }
      return {
        loading: false,
        value: Auxiliary.createTreeDataFromTitlesBuzzSentiment(titlesForBuzzSentiment, historicBuzzSentiment.value.value)
      }
    }
  })

  const buzzSentimentTitles = computed<AsyncData<BuzzSentimentTitlesTableRow[]>>(
    (prevBuzzSentimentTitles : AsyncData<BuzzSentimentTitlesTableRow[]>|undefined) => {
      if (titles.value.value === undefined || titles.value.loading) {
        return { loading: true, value: [] }
      }
      if (!timeRange.isTimeSpanSelected() && historicBuzzSentiment.value.loading) {
        return { loading: true, value: [] }
      }

      // We do not want to use the ref of titles!
      const titlesForBuzzSentiment = Array.from(titles.value.value)

      const prevData = prevBuzzSentimentTitles?.value || []
      if (timeRange.isTimeSpanSelected()) {
        return {
          loading: false,
          value: Auxiliary.createTitlesBuzzSentimentTable(titlesForBuzzSentiment, titlesRealtimeData.value, prevData)
        }
      } else {
        if (historicBuzzSentiment.value.value === undefined) {
          return { loading: false, value: [] }
        }
        return {
          loading: false,
          value: Auxiliary.createTitlesBuzzSentimentTable(titlesForBuzzSentiment, historicBuzzSentiment.value.value, prevData)
        }
      }
    })

  async function getReloadMessagesHash ():Promise<string> {
    return await StringHelper.createIdentifier(sourceFilter.activeSourceValue, messageFilter.value, timeRange.selectedTimeRange, searchQuery.value)
  }
  async function getReloadAllHash ():Promise<string> {
    return await StringHelper.createIdentifier(titleFilter.value)
  }

  function setLoadingState (all: boolean, loading:boolean) {
    if (all) {
      topicsList.value.loading = loading
      executiveSummary.value.loading = loading
      referencedPeople.value.loading = loading
      entityInformation.value.loading = loading
    }
    titles.value.loading = loading
    messageStatistics.value.loading = loading
    messages.value.loading = loading
    keyEventsForTitles.value.loading = loading
    historicBuzzSentiment.value.loading = loading
    indexChartData.value.loading = loading
    indexQuoteChartData.value.loading = loading
    referencedPeople.value.loading = loading
    referencedPeopleInfos.value.loading = loading
  }

  async function loadData (): Promise<void> {
    const newReloadAllHash = await getReloadAllHash()
    const newReloadMessagesHash = await getReloadMessagesHash()

    const reloadAll = reloadAllHash.value !== newReloadAllHash
    const reloadMessages = reloadMessagesHash.value !== newReloadMessagesHash

    if (!reloadAll && !reloadMessages) {
      return
    }

    reloadAllHash.value = newReloadAllHash
    reloadMessagesHash.value = newReloadMessagesHash

    setLoadingState(reloadAll, true)

    if (reloadAll) {
      top5ChartData.value = { loading: true }
      axiosRequestManager.cancelRequests()
    } else {
      const doNotCancelFilter = (request: AxiosRequestConfig) => {
        if (!request.url?.includes('messages/search')) {
          return false
        }
        if (typeof request.data !== 'string') {
          return false
        }
        if (request.data.includes('"include_exec_summary":true') || request.data.includes('"include_topics_list":true')) {
          return true
        }
        return false
      }
      axiosRequestManager.cancelRequests(doNotCancelFilter)
    }

    const requests: Promise<void>[] = []

    if (subscriptionIds.value.length > 0) {
      realtimeDataStore.unsubscribeTitleUpdates(subscriptionIds.value)
    }
    unsubscribeFromTitleMessagesWebsocket()

    entityType.value = titleFilter.value && getEntityType(titleFilter.value)

    // We need to wait for the Statistics to get the titlesToDisplay
    await loadStatistics()

    // Handle no titles found
    if (!titles.value?.value?.length) {
      setLoadingState(true, false)
      return
    }

    // Subscribe to Titles and TitleMessages
    subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(titles.value.value.map(title => title.id)))
    subscribeToWebsocketForTitleMessages()

    requests.push(loadTitleKeyEvents())
    requests.push(loadReferencedPeople())

    if (!timeRange.isTimeSpanSelected()) {
      requests.push(loadHistoricBuzzSentiment())
    } else {
      historicBuzzSentiment.value = {
        loading: false,
        value: []
      }
    }

    if (reloadAll) {
      requests.push(loadTopicsList())
      requests.push(loadSummary())

      // Handle Index differently
      if (entityType.value === ListEntityType.LIST) {
        requests.push(loadIndexChartData())
        requests.push(loadIndexInformation())
      } else {
        indexChartData.value = {
          loading: false,
          value: undefined
        }
        indexQuoteChartData.value = {
          loading: false,
          value: undefined
        }
      }
    } else if (reloadMessages && entityType.value === ListEntityType.LIST) {
      requests.push(loadIndexChartData())
    }

    await Promise.all(requests)
  }

  const messageProcessingThrottleMS = 6000
  const throttlingMessageProcessor = new ThrottlingMessageProcessor(messages,
    messageStatistics, notificationService, ROUTE_NAME_VIEW, messageProcessingThrottleMS)
  const throttledMessageProcessing = useBufferedThrottleFn(throttlingMessageProcessor.processMessages, messageProcessingThrottleMS)

  const messageHistoryChartData: ComputedRef<{ loading: boolean } & { history?: Array<StackItem> }> = computed(() => {
    return prepareMessageHistoryChartData(messageStatistics.value)
  })

  async function loadTitleKeyEvents () {
    const titleIdent = titles.value.value?.map(title => title.id.toString()).join(',')
    if (!titleIdent) {
      return
    }

    const startDate = timeRange.getCurrentStartDate()
    const endDate = timeRange.getCurrentEndDate()
    const reportModel = getDefaultReportModel(startDate, endDate, false)

    const fetchedKeyEvents = await keyEventsV2ApiFactory.getKeyEventsV2ForTitles(
      titleIdent,
      // TODO_NEXT Fix for 10 Minute KeyEventData. reportModel.name
      'daily_5am',
      timeRange.getApiTimestamp(startDate),
      timeRange.getApiTimestamp(endDate)
    )

    keyEventsForTitles.value = {
      loading: false,
      value: fetchedKeyEvents.data
    }
  }

  async function loadReferencedPeople () {
    if (messageStatistics.value.value?.top10?.entities) {
      const fetchedReferencedPeople = await extractReferencedPeople(messageStatistics.value.value?.top10?.entities)
      referencedPeople.value = {
        loading: false,
        value: fetchedReferencedPeople?.statistics
      }

      referencedPeopleInfos.value = {
        loading: false,
        value: fetchedReferencedPeople?.entities
      }
    } else {
      referencedPeople.value = {
        loading: false,
        value: []
      }
      referencedPeopleInfos.value = {
        loading: false,
        value: []
      }
    }
  }

  async function loadStatistics (): Promise<void> {
    const titleIds = [...messageFilter.value.asset]
    // We include list ids for message statistics
    if (titleFilter.value?.list.length) {
      titleIds.push(...titleFilter.value?.list)
    }

    const keyEventIds = [...messageFilter.value.keyEvent]
    if (titleFilter.value?.keyEvent?.length) {
      keyEventIds.push(...titleFilter.value?.keyEvent)
    }

    let solrTitleFilters: SolrTitleFilters|undefined
    if (titleFilter.value) {
      const titleFilterWithoutKeyEvent = { ...titleFilter.value }
      titleFilterWithoutKeyEvent.keyEvent = []
      const filterList = Auxiliary.getFilterSettingsListByTitleFilter(titleFilterWithoutKeyEvent)

      solrTitleFilters = {
        filters: filterList,
        add_to_search: true,
        restrict_response: true
      }
    }

    const statisticsData = await fetchStatistics(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titleIds,
      sourceFilter.activeSourceValue,
      keyEventIds,
      searchQuery.value,
      messageFilter.value.messageLanguage,
      messageFilter.value.messageType,
      100,
      undefined,
      solrTitleFilters
    )

    messageStatistics.value = {
      loading: false,
      value: statisticsData.statistics
    }

    // We have to wait for the statistics to load the referenced people
    const top10Entities = messageStatistics.value.value?.top10?.entities

    if (messageStatistics.value.value?.top10?.titles) {
      const top100TitleIds = Object.keys(messageStatistics.value.value?.top10?.titles).map(Number)
      let titleInfos = await fetchInfosForTitles(top100TitleIds)
      if (!Array.isArray(titleInfos)) {
        titleInfos = [titleInfos]
      }
      titles.value = {
        loading: false,
        value: titleInfos.map(titleInfo => {
          return {
            name: titleInfo.n,
            id: titleInfo.id,
            type: titleInfo.type,
            buzz: titleInfo.buzz['2'],
            buzz_open: titleInfo.buzz['4'],
            currency: titleInfo.currency || 'PTS'
          }
        })
      }
    } else {
      titles.value = {
        loading: false,
        value: []
      }
    }

    messages.value = {
      loading: false,
      value: statisticsData.messages?.filter(message =>
        message.titles?.some(messageTitle =>
          titles.value.value?.some(title => title.id === messageTitle.title_id))
      )
    }
  }

  async function loadTopicsList (): Promise<void> {
    const fetchedTopicsList = await fetchTopicsList(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titles.value.value ? titles.value.value?.map(title => title.id) : [],
      sourceFilter.activeSourceValue
    )
    topicsList.value = {
      loading: false,
      value: fetchedTopicsList.topics_list
    }
  }

  async function loadSummary (): Promise<void> {
    if (titles.value.value === undefined || titles.value.value?.length === 0) {
      executiveSummary.value = {
        loading: false
      }
      return
    }
    const fetchedSummary = await fetchSummary(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titles.value.value?.map(title => title.id)
    )
    executiveSummary.value = {
      loading: false,
      value: {
        summary: fetchedSummary.executive_summary,
        messages: fetchedSummary.messages
      }
    }
  }

  async function loadHistoricBuzzSentiment (): Promise<void> {
    if (titles.value.value === undefined) {
      historicBuzzSentiment.value = {
        loading: false,
        value: []
      }
      return
    }

    const buzzSentiment = await buzzSentimentAPI.getHistoricBuzzSentimentForTitles(
      titles.value.value?.map(title => title.id).join(','),
      '',
      timeRange.getApiTimestamp(timeRange.getCurrentStartDate()),
      timeRange.getApiTimestamp(timeRange.getCurrentEndDate()),
      Auxiliary.getDaily5AMPartitionNameForSource(sourceFilter.activeSourceValue)
    )
    const history = buzzSentiment.data.history as [{b:number, s:number, t:number, id:number}]
    const result : {[key: number]:{ sumS:number, sumB:number, count:number, t:number}} = {}
    history.forEach(obj => {
      const { id, s, b, t } = obj

      if (!result[id]) {
        result[id] = { sumS: 0, sumB: 0, count: 0, t: 0 }
      }

      // Accumulate sums and count for each id
      result[id].sumS += s
      result[id].sumB += b
      result[id].count++
      result[id].t = t
    })

    const titlesBuzzSentimentDataCollection : TitlesBuzzSentimentDataCollection = {}
    for (const id in result) {
      const { t, sumS, sumB, count } = result[id]
      const sentiment = sumS / count
      const buzz = sumB / count

      const sentimentScore = Auxiliary.calculateSentimentScore(sentiment || 0) / 100
      const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
      const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)

      titlesBuzzSentimentDataCollection[id] = {
        id: parseInt(id),
        buzz,
        sentiment: sentiment || 0,
        sentimentIcon,
        sentimentIconColor,
        sentimentScore,
        time: t
      }
    }

    historicBuzzSentiment.value = {
      loading: false,
      value: titlesBuzzSentimentDataCollection
    }
  }

  async function loadIndexChartData (startDateIn? : Date, endDateIn?: Date, isExtendedTimeRange = true): Promise<void> {
    const startDate = startDateIn || timeRange.getCurrentExtendedStartDate()
    const endDate = endDateIn || timeRange.getCurrentEndDate()
    const listId = titleFilter.value?.list[0]
    if (listId === undefined) {
      indexChartData.value = {
        loading: false,
        value: undefined
      }
      indexQuoteChartData.value = {
        loading: false,
        value: undefined
      }
      return
    }
    const fetchedChartData = await fetchChartData(
      sourceFilter.activeSourceValue,
      startDate,
      endDate,
      [listId],
      isExtendedTimeRange
    )

    indexChartData.value = {
      loading: false,
      value: fetchedChartData
    }

    const fetchedQuoteChartData = await fetchQuoteChartData(
      sourceFilter.activeSourceValue,
      startDate,
      endDate,
      [listId],
      isExtendedTimeRange
    )
    indexQuoteChartData.value = {
      loading: false,
      value: fetchedQuoteChartData
    }
  }

  async function loadIndexInformation (): Promise<void> {
    const listIds = titleFilter.value?.list
    if (listIds === undefined) {
      return
    }

    await commonStore.loadTitleInfos(listIds)

    const top5Titles : Title[] = []
    // We filter by list of current titles
    titles.value.value?.slice(0, 5)?.forEach(title => {
      top5Titles.push(title)
    })
    indexTitleInformation.value.value?.forEach(indexTitleInfo => {
      top5Titles.unshift({
        name: indexTitleInfo.n,
        id: indexTitleInfo.id,
        type: indexTitleInfo.type,
        buzz: indexTitleInfo.buzz[2],
        buzz_open: indexTitleInfo.buzz[4],
        currency: 'PTS'
      })
    })
    await loadTop5TitleChartData(top5Titles)
  }

  async function loadNextMessages (authorScore?:number): Promise<number> {
    const titleIds = titles.value.value?.map(title => title.id)

    let startDate = timeRange.getCurrentStartDate()
    let endDate = timeRange.getCurrentEndDate()

    if (messages.value.value && messages.value.value.length > 0) {
      const oldestMessage = messages.value.value.sort((a, b) => a.t - b.t)[0].t
      startDate = sub(oldestMessage * 1000, { days: 7 }) // JLDEBUG müssen wir hier überhaupt das StartDate setzen?
      endDate = sub(oldestMessage * 1000, { seconds: 1 })
    }

    const nextMessages = await fetchSolrMessages(
      startDate,
      endDate,
      titleIds || [],
      sourceFilter.activeSourceValue,
      messageFilter.value.keyEvent,
      messageFilter.value.messageType,
      searchQuery.value,
      messageFilter.value.messageLanguage,
      authorScore
    )

    const fetchedMessages: Message[] = []
    nextMessages.forEach((message: Message) => fetchedMessages.push(message))
    const uniqueMessagesMap = new Map(fetchedMessages.map(obj => [obj.message_id, obj]))
    const uniqueMessages = [...uniqueMessagesMap.values()]
    if (uniqueMessages.length === 0) {
      return 0
    }

    if (!messages.value.value) {
      messages.value.value = uniqueMessages
    } else {
      messages.value.value.push(...uniqueMessages)
    }

    return uniqueMessages.length
  }

  function resetMessages () : void {
    messages.value.value = []
  }

  function subscribeToWebsocketForTitleMessages () {
    if (!titles.value.value) {
      return
    }

    const unsubscribeMessagesFunction = webSocket.emitSubscribeTitlesMessages(
      titles.value.value?.map(title => title.id),
      handleWebsocketMessage
    )
    wsMessageListeners.value.push(unsubscribeMessagesFunction)
  }

  function handleWebsocketMessage (data: unknown, error: unknown): void {
    if (error) {
      return
    }
    const message = data as any
    if (!message || !messages.value.value) {
      return
    }

    // If a time range is selected we do not want updates of messages
    if (!timeRange.isTimeSpanSelected()) {
      return
    }

    if (
      (sourceFilter.activeSourceValue !== '' && message.so !== sourceFilter.activeSourceValue) ||
      !message.titles?.some((messageTitleId: any) => titles.value.value?.map(title => title.id).includes(messageTitleId.title_id)) ||
      // TODO_NEXT Is there a better way to do it?
      ((searchQuery.value !== '' && (!message.body?.toLowerCase().includes(searchQuery.value) && !message.sub?.toLowerCase().includes(searchQuery.value))))
    ) {
      return
    }
    if (messages.value.value.find(mess =>
      mess.url === message.url
    )) {
      return
    }
    throttledMessageProcessing([message])
  }

  function unsubscribeFromTitleMessagesWebsocket () {
    wsMessageListeners.value.forEach(unsubscribeCallback => unsubscribeCallback())
  }

  async function loadTop5TitleChartData (titles : Title[]): Promise<void> {
    const top5TitleChartData: TitleChartData[] = []
    if (titles.length > 0) {
      const endDate = timeRange.getCurrentEndDate()
      const startDate = sub(endDate, { days: 90 })

      for (const title of titles) {
        try {
          const titleChartData = await fetchTitleChartData(
            title,
            startDate,
            endDate
          )
          top5TitleChartData.push(titleChartData)
          if (top5ChartData.value.value && top5ChartData.value.value?.length >= 5) {
            break
          }
        } catch (e) {
          console.log('Error fetching quotes')
        }
      }
    }

    top5ChartData.value = {
      loading: false,
      value: top5TitleChartData
    }
  }

  return {
    titles,
    titleFilter,
    messageFilter,
    loadData,
    setFilter,
    entityType,
    loadNextMessages,
    resetMessages,
    titlesTreeData,
    topicsList,
    executiveSummary,
    messageStatistics,
    messageHistoryChartData,
    messages,
    entityInformation,
    referencedPeople,
    referencedPeopleInfos,
    top5ChartData,
    keyEventsForTitles,
    indexChartData,
    indexQuoteChartData,
    indexTitleInformation,
    loadIndexChartData,
    buzzSentimentTitles
  }
})
