import { i18n } from 'boot/i18n'
import { useCommonStore } from 'stores/common-store'
import {
  QuotesForTitlesQuotesInner,
  SearchMessagesStatistics,
  SearchMessagesStatisticsAuthorAnalytics,
  SearchMessagesStatisticsCounts
} from '@stockpulse/typescript-axios'
import * as StringHelper from 'src/helper/StringHelper'
import { Auxiliary } from 'src/helper/Auxiliary'
import { ReportModel, ReportModelType } from 'src/helper/ReportHelper'
import { AsyncData } from 'components/models'
import * as Sentry from '@sentry/vue'

export interface StatisticData {
  sourceDistribution: { label: string, value: number, identifier:string }[] | undefined,
  typeDistribution: { label: string, value: number, identifier:string }[] | undefined,
  authorDistribution: { label: string, value: number, id: number, source:string }[] | undefined,
  languageDistribution: { label: string, value: number, identifier: string }[] | undefined,
  titlesDistribution: { label: string, value: number, id: number }[] | undefined,
  keyEvents: { label: string, value: number }[] | undefined
  authorAnalytics: { label: string, value: number }[] | undefined
}

export interface StatisticCountData {
  authorCount: number,
  sourcesCount: number,
  totalCount: number,
  positiveMessagesCount: number,
  negativeMessagesCount: number,
  neutralMessagesCount: number
}

export interface SearchMessagesStatisticsTop10AuthorsInner {
  'source'?: string;
  'name'?: string;
  'count'?: number;
  author_id: number
}

export interface StackItem { id: number, t?: number, name?: string, total?: number | null }

export function extractStatisticData (messagesStatistics: SearchMessagesStatistics, excludeTitleId?: number): StatisticData {
  const commonStore = useCommonStore()

  const top10 = messagesStatistics?.top10
  const authorAnalytics = messagesStatistics?.author_analytics
    ? getAuthorAnalytics(messagesStatistics?.author_analytics)
    : undefined
  const sourceDistribution = Object.entries(top10?.sources || {}).map((value) => {
    return { label: value[0], value: value[1], identifier: value[0] }
  })
  const typeDistribution = Object.entries(top10?.types || {}).map((value) => {
    return { label: i18n.global.t('MessageStatistics.type.' + value[0]), value: value[1], identifier: value[0] }
  })

  const authorDistribution = (top10?.authors as SearchMessagesStatisticsTop10AuthorsInner[] || []).map((value) => {
    return { label: value.name || '', value: value.count || 0, id: value.author_id, source: value.source || '' }
  })
  const languageDistribution = Object.entries(top10?.languages || {}).map((value) => {
    const translation = i18n.global.t('MessageStatistics.language.' + value[0])
    let label = translation
    if (translation.startsWith('MessageStatistics.language.')) {
      label = value[0]
      Sentry.captureMessage(`Could not find translation key for language 'MessageStatistics.language.${value[0]}'`)
    }

    return {
      label,
      value: value[1],
      identifier: value[0]
    }
  })
  const titlesDistribution = Object.entries(top10?.titles || {}).filter(value => excludeTitleId === undefined || parseInt(value[0]) !== excludeTitleId).map((value) => {
    return {
      label: commonStore.getTitleNameById(parseInt(value[0])),
      value: value[1],
      id: parseInt(value[0])
    }
  })
  const keyEvents = Object.entries(top10?.key_events || {}).map((value) => {
    return { label: commonStore.translateKeyEvent(parseInt(value[0])), value: value[1], id: parseInt(value[0]) }
  })

  return {
    sourceDistribution,
    typeDistribution,
    authorDistribution,
    languageDistribution,
    titlesDistribution,
    keyEvents,
    authorAnalytics
  }
}

function getAuthorAnalytics (rawAuthorAnalytics : SearchMessagesStatisticsAuthorAnalytics)
  : Array<{label: string, value:number}> {
  const defaultData: SearchMessagesStatisticsAuthorAnalytics = { bots: 0, influencers: 0, regulars: 0, verified: 0 }
  const analytics: SearchMessagesStatisticsAuthorAnalytics = { ...defaultData, ...rawAuthorAnalytics }
  const total = Object.values(analytics).reduce((a, b) => a + b, 0)
  if (total === 0) {
    return []
  }

  return Object.entries(analytics).map(
    (value: [string, number]) => {
      return {
        label: StringHelper.capitalizeFirstLetter(value[0]),
        value: Math.round(value[1] * 100 / total * 10) / 10 || 0
      }
    }
  )
}

export function extractStatisticCountData (statisticsCounts: SearchMessagesStatisticsCounts): StatisticCountData {
  const authorCount = statisticsCounts?.authors || 0
  const sourcesCount = statisticsCounts?.sources || 0
  const totalCount = statisticsCounts?.messages || 0
  const positiveMessagesCount = statisticsCounts?.pos || 0
  const negativeMessagesCount = statisticsCounts?.neg || 0
  const neutralMessagesCount = statisticsCounts?.neu || 0

  return {
    authorCount,
    sourcesCount,
    totalCount,
    positiveMessagesCount,
    negativeMessagesCount,
    neutralMessagesCount
  }
}

export interface BuzzSentimentHistory {
    buzzHistory: [number, number][],
    sentimentHistory: [number, number][],
    minDate: number,
    maxDate: number,
    minInterval: number
}

export interface QuoteHistory {
    quoteHistory: [number, number | null][]
    volumeHistory: [number, number | null][]
}

export function prepareChartData (
  buzzSentimentInputHistory: { history: Array<{ t:number, Buzz: number, Sentiment: number }>, reportModel: ReportModel },
  quoteInputHistory: { history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel }
): { buzzSentimentHistory: BuzzSentimentHistory, quoteHistory: QuoteHistory } {
  const buzzSentimentHistory = prepareBuzzSentimentHistory(buzzSentimentInputHistory)
  const quoteHistory = prepareQuoteHistory(quoteInputHistory)

  const quoteHistoryTimestamps = quoteHistory.quoteHistory.map(item => item[0])

  if (buzzSentimentInputHistory.reportModel.type === ReportModelType.REALTIME) {
    // Add zeros for every missing timestamp in quoteHistory
    for (const [timestamp] of buzzSentimentHistory.buzzHistory) {
      if (!quoteHistoryTimestamps.includes(timestamp)) {
        quoteHistory.quoteHistory.push([timestamp, null])
        quoteHistory.volumeHistory.push([timestamp, null])
      }
    }
  }

  // Sort series data by timestamp
  quoteHistory.quoteHistory.sort((a, b) => a[0] - b[0])
  quoteHistory.volumeHistory.sort((a, b) => a[0] - b[0])

  return { buzzSentimentHistory, quoteHistory }
}

export function prepareMessageHistoryChartData (messageStatistics : AsyncData<SearchMessagesStatistics>) {
  if (messageStatistics.loading) {
    return { loading: true }
  }
  const history = messageStatistics.value?.history
  if (!history) {
    return { loading: false }
  }

  const positiveMessages: Array<StackItem> = history.map(item => {
    return { id: 1, name: 'Positive', total: item.pos, t: item.t }
  })

  const neutralMessages: Array<StackItem> = history.map(item => {
    return { id: 2, name: 'Neutral', total: item.neu, t: item.t }
  })

  const negativeMessages: Array<StackItem> = history.map(item => {
    return { id: 3, name: 'Negative', total: item.neg, t: item.t }
  })

  return { history: positiveMessages.concat(...neutralMessages, ...negativeMessages), loading: false }
}

function prepareBuzzSentimentHistory (
  values: { history: Array<{ t:number, Buzz:number, Sentiment:number }>, reportModel: ReportModel }
): BuzzSentimentHistory {
  const buzzValues : [number, number][] = []
  const sentimentValues : [number, number][] = []

  const minDate: number = Math.min(...values.history.map(item => item.t))
  const maxDate: number = Math.max(...values.history.map(item => item.t))

  switch (values.reportModel.type) {
  case ReportModelType.REALTIME:
    values.history.forEach(entry => {
      buzzValues.unshift([get10MinTimestamp(entry.t), entry.Buzz * 100])
      sentimentValues.unshift([get10MinTimestamp(entry.t), Auxiliary.calculateSentimentScore(entry.Sentiment)])
    })

    return {
      buzzHistory: buzzValues,
      sentimentHistory: sentimentValues,
      minDate: new Date(get10MinTimestamp(minDate)).getTime(),
      maxDate: new Date(get10MinTimestamp(maxDate)).getTime(),
      minInterval: 10 * 60 * 1000
    }
  case ReportModelType.DAILY:
    values.history.forEach(entry => {
      buzzValues.unshift([getTodaysTimestamp(entry.t), entry.Buzz * 100])
      sentimentValues.unshift([getTodaysTimestamp(entry.t), Auxiliary.calculateSentimentScore(entry.Sentiment)])
    })

    return {
      buzzHistory: buzzValues,
      sentimentHistory: sentimentValues,
      minDate: new Date(getTodaysTimestamp(minDate)).getTime(),
      maxDate: new Date(getTodaysTimestamp(maxDate)).getTime(),
      minInterval: 24 * 3600 * 1000
    }
  default:
    throw new Error(`Unexpected report model type: ${values.reportModel.type}`)
  }
}

function prepareQuoteHistory (values: { history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel }): QuoteHistory {
  const quoteValues : [number, number][] = []
  const volumeValues : [number, number][] = []

  switch (values.reportModel.type) {
  case ReportModelType.REALTIME:
    values.history.forEach(entry => {
      if (!entry.t || !entry.o) {
        return
      }

      const normalizedTimestamp = get10MinTimestamp(entry.t)
      // Do not include more than one value per normalizedTimestamp
      if (entry.o && !quoteValues.map(item => item[0]).includes(normalizedTimestamp)) {
        quoteValues.unshift([normalizedTimestamp, entry.o])
        volumeValues.unshift([normalizedTimestamp, entry.v || 0])
      }
    })

    return {
      quoteHistory: quoteValues,
      volumeHistory: volumeValues
    }
  case ReportModelType.DAILY:
    values.history.forEach(entry => {
      if (!entry.t || !entry.o) {
        return
      }

      const normalizedTimestamp = getTodaysTimestamp(entry.t)
      // Do not include more than one value per normalizedTimestamp
      if (entry.o && !quoteValues.map(item => item[0]).includes(normalizedTimestamp)) {
        quoteValues.unshift([normalizedTimestamp, entry.o])
        volumeValues.unshift([normalizedTimestamp, entry.v || 0])
      }
    })

    return {
      quoteHistory: quoteValues,
      volumeHistory: volumeValues
    }
  default:
    throw new Error(`Unexpected report model type: ${values.reportModel.type}`)
  }
}

export function getTodaysTimestamp (timestamp: number) : number {
  return (new Date(timestamp * 1000)).setHours(0, 0, 0, 0)
}

export function get10MinTimestamp (timestamp: number) : number {
  return (new Date(timestamp * 1000)).setSeconds(0, 0)
}
