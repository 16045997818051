import { useRuntimeConfig } from 'src/services/RuntimeConfig'
import { boot } from 'quasar/wrappers'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { date as DateFormatter } from 'quasar'

import {
  BuzzSentiment,
  CountriesInner, EntityType,
  ListsInner,
  Message,
  PersonV6,
  QuotesForTitlesQuotesInner,
  SearchMessages,
  SearchMessagesStatisticsTop10Entities,
  TitleInformation,
  SearchMessagesStatisticsTop10
} from '@stockpulse/typescript-axios'
import { differenceInMilliseconds } from 'date-fns'
import { Title, TitleChartData } from 'stores/list-view-store'
import { AxiosRequestManager, RouteThrottleConfig } from 'src/services/AxiosRequestManager'
import { ExchangeData, InventorySector, InventoryTitle, KeyEvent } from 'stores/common-store'
import {
  DEFAULT_REPORT_MODELS,
  getReportModel,
  ReportModel,
  ReportModelType
} from 'src/helper/ReportHelper'
import { AuthorHistoryData } from 'stores/author-view-store'
import {
  SearchMessagesStatisticsAuthorAnalytics
} from '@stockpulse/typescript-axios/types/search-messages-statistics-author-analytics'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
    $api: AxiosInstance
  }
}

export interface FilterSetting {
  field: string;
  value: string | string[] | number | number[];
  comparator: 'gt' | 'lt' | 'gte' | 'lte' | 'eq'
}

export type FilterSettingsList = FilterSetting[];

export interface ViewFilter {
  asset: number[],
  list: number[],
  sector: number[],
  exchange: number[],
  region: string[],
  country: string[],
  keyEvent: number[],
  type: EntityType[]
}

export interface MessageFilter {
  messageType: string[],
  messageLanguage: string[],
  authorType: string[],
  keyEvent: number[],
  asset: number[]
}

export interface ViewFilterEntities {
  list: ListsInner[],
  sector: InventorySector[],
  exchange: ExchangeData[],
  country: CountriesInner[],
  region?: any[],
  keyEvent?: KeyEvent[],
  asset?: InventoryTitle[]
}

export interface SolrTitleFilters {
  filters: FilterSetting[],
  add_to_search: boolean,
  restrict_response: boolean
}

export interface TitlesBuzzSentiment { [key: number]: BuzzSentiment }

// TODO_NEXT: Update API Typedefinitions
declare module '@stockpulse/typescript-axios' {
  interface SearchMessagesStatisticsTop10Titles {
    [key: number]: number
  }

  interface SearchMessagesStatisticsTop10AuthorsInner {
    [key: number]: number
  }

  interface SearchMessagesStatisticsTop10KeyEvents {
    [key: number]: number
  }

  interface SearchMessagesStatisticsCounts {
    pos: number,
    neg: number,
    neu: number
  }

  interface SearchMessagesStatisticsHistoryInner {
    pos: number,
    neg: number,
    neu: number
  }

  interface AuthorStatistics {
    history: AuthorHistoryData[]
  }

  interface TitleInformation {
    url: string
  }
  interface ListsInner {
    icon: string
  }
  interface TitleInventoryInner {
    buzz?: number,
    sentiment?: number,
    b_o?: number,
    s_o?: number,
    t: number
  }
  interface SearchMessagesStatistics {
    'oldest_message'?: Message,
    'youngest_message'?: Message,
    'average_sentiment'?: number,
    'counts'?: SearchMessagesStatisticsCounts,
    'top10'?: SearchMessagesStatisticsTop10,
    'history'?: Array<SearchMessagesStatisticsHistoryInner>,
    'author_analytics'?: SearchMessagesStatisticsAuthorAnalytics,
    'title_messages'?: {[key: number]: {total:number, pos:number, neg:number, sentiment:number}}
  }
}

const fetchState = true

const runtimeConfig = useRuntimeConfig()

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: runtimeConfig.API_BASE_URL,
  headers: {
    'x-app-version': process.env.APP_VERSION_NAME,
    // TODO: test to avoid Edge cross-origin cache problem
    'Cache-Control': 'private, no-cache, no-store, must-revalidate'
    // Expires: '-1'
    // Pragma: 'no-cache'
  }
})

const routeThrottleConfigs: RouteThrottleConfig[] = [
  { route: '/v6', concurrency: 4 },
  { route: '', concurrency: 10 }
]

const axiosRequestManager = new AxiosRequestManager(routeThrottleConfigs)

export default boot(({ app, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  api.interceptors.request.use((config: AxiosRequestConfig) => axiosRequestManager.interceptRequest(config))

  api.interceptors.response.use(
    (response: AxiosResponse) => axiosRequestManager.interceptResponse(response),
    (error) => axiosRequestManager.interceptResponseError(error)
  )
})

async function fetchTitleInfos (titleId: number): Promise<TitleInformation> {
  return await fetchInfosForTitles([titleId]) as TitleInformation
}

async function fetchInfosForTitles (titleIds: number[]): Promise<TitleInformation|TitleInformation[]> {
  const response = await api.get(`/v6/titles/${titleIds.join(',')}/info`)
  return response.data
}

async function fetchStatistics (
  startDate: Date,
  endDate: Date,
  titleIds: number[],
  sourceFilter: string,
  keyEventIds?: number[],
  query? :string,
  lang? : string[],
  type?:string[],
  topLimit?:number,
  authors?: {source:string, name:string}[],
  titleFilters?:SolrTitleFilters
)
  : Promise<SearchMessages> {
  const body = {
    startDate,
    endDate,
    include_stats: true,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    add_icon: true,
    key_events: keyEventIds,
    query,
    lang,
    type: type?.flatMap(str => str.includes(',') ? str.split(',') : str),
    top_limit: topLimit,
    authors,
    title_filters: titleFilters
  }
  const response = await api.post('/v6/messages/search', body)
  return response.data
}

async function fetchSolrMessages (
  startDate: Date,
  endDate: Date,
  titleIds: number[],
  sourceFilter: string,
  keyEventIds?: number[],
  type?: string[],
  query?:string,
  lang?: string[],
  score?: number,
  authors?: {source:string, name:string}[]
)
  : Promise<Array<Message>> {
  const body = {
    startDate,
    endDate,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    add_icon: true,
    key_events: keyEventIds,
    type: type?.flatMap(str => str.includes(',') ? str.split(',') : str),
    query,
    lang,
    score,
    authors
  }

  const response = await api.post('/v6/messages/search', body)
  return response.data?.messages || []
}

interface SearchMessagesWithTopics {
  /**
   *
   * @memberof SearchMessages
   */
  'topics_list': string[];
}

async function fetchTopicsList (startDate: Date, endDate: Date, titleIds: number[], sourceFilter: string, keyEventIds?: number[])
  : Promise<SearchMessagesWithTopics> {
  const body = {
    startDate,
    endDate,
    include_topics_list: true,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    key_events: keyEventIds
  }

  const response = await api.post('/v6/messages/search', body)
  return response.data
}

async function fetchSummary (startDate: Date, endDate: Date, titleIds: number[], keyEventIds?: number[]) {
  const body = {
    startDate,
    endDate,
    core: getCoreToUse(startDate),
    titles: titleIds,
    limit: 10,
    type: 'news',
    include_stats: false,
    order_by: 'score',
    include_exec_summary: true,
    highlight_titles_in_summary: true,
    key_events: keyEventIds
  }
  const response = await api.post('/v6/messages/search', body)
  return response.data
}

async function fetchChartData (source: string, startDate: Date, endDate: Date, titleIds: number[], isExtendedTimeRange = true): Promise<any> {
  const reportModel = getReportModel(source, startDate, endDate, isExtendedTimeRange)

  const postBody = {
    datasets: [
      {
        feature: 'Buzz/Sentiment',
        fields: [
          {
            field: 'buzz',
            name: 'Buzz'
          },
          {
            field: 'sentiment',
            name: 'Sentiment'
          }
        ],
        item: 'Buzz Reports',
        report_model: reportModel.id,
        titles: titleIds,
        type: 'column'
      }
    ],
    filter: [
      {
        comparator: 'eq',
        field: 'id',
        value: titleIds
      }
    ],
    identifiers: [
      'id'
    ]
  }

  const queryParams = {
    preview: false,
    startDate,
    endDate
  }

  const response = await api.post(
    '/v6/datasets/history',
    postBody,
    { params: queryParams }
  )

  return { ...response.data, reportModel }
}

async function fetchQuoteChartData (source: string, startDate: Date, endDate: Date, titleIds: number[], isExtendedTimeRange = true): Promise<{ history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel }> {
  const reportModel = getReportModel(source, startDate, endDate, isExtendedTimeRange)

  if (reportModel.type === ReportModelType.DAILY) {
    return { ...(await fetchDailyQuoteChartData(startDate, endDate, titleIds)), reportModel }
  }

  return { ...(await fetchIntradayQuoteChartData(startDate, endDate, titleIds)), reportModel }
}

async function fetchIntradayQuoteChartData (startDate: Date, endDate: Date, titleIds: number[]): Promise<{ history: Array<QuotesForTitlesQuotesInner> }> {
  const queryParams = {
    startDate,
    endDate,
    partition: DEFAULT_REPORT_MODELS.realtime.name,
    data_options: 'ohlcv'
  }

  const response = await api.get(`/v6/titles/${titleIds.join(',')}/intraday`, { params: queryParams })
  return { history: response.data.quotes }
}

async function fetchDailyQuoteChartData (startDate: Date, endDate: Date, titleIds: number[]): Promise<{ history: Array<QuotesForTitlesQuotesInner> }> {
  const postBody = {
    datasets: [
      {
        feature: 'Price Data',
        fields: [
          { field: 'open', name: 'o' },
          { field: 'high', name: 'h' },
          { field: 'low', name: 'l' },
          { field: 'close', name: 'c' },
          { field: 'volume', name: 'v' }
        ],
        item: 'Daily Quotes',
        report_model: DEFAULT_REPORT_MODELS.daily.id,
        titles: titleIds
      }
    ],
    filter: [
      {
        comparator: 'eq',
        field: 'id',
        value: titleIds
      }
    ],
    identifiers: [
      'id'
    ]
  }

  const queryParams = { preview: false, startDate, endDate }

  const response = await api.post('/v6/datasets/history', postBody, { params: queryParams })
  return response.data
}

function getCoreToUse (startDate : Date) {
  return differenceInMilliseconds(new Date(), startDate) < 24 * 60 * 60 * 1000
    ? 'messages24h'
    : 'messages'
}

async function extractReferencedPeople (top10Entities : SearchMessagesStatisticsTop10Entities) {
  const entityIds = Object.entries(top10Entities).map(
    (value) => parseInt(value[0])
  )
  const entities = await fetchEntities(entityIds)

  const statistics = Object.entries(top10Entities).map((value) => {
    return {
      label: getEntityName(parseInt(value[0]), entities),
      value: value[1] as number,
      id: parseInt(value[0])
    }
  })
  return {
    statistics,
    entities
  }
}

async function fetchEntities (entityIds: number[]) : Promise<PersonV6[]> {
  if (entityIds.length === 0) {
    return []
  }
  const response = await api.get(`/v6/entities/${entityIds.join(',')}?format=json`)
  return response.data
}

function getEntityName (entityId: number, entities: Array<{ entity_id: number, n: string }>): string {
  return entities?.filter((entity) => entity.entity_id === entityId).at(0)?.n || ''
}

async function fetchTitleChartData (title: Title, startDate : Date, endDate : Date): Promise<TitleChartData> {
  const response = await api.get(`/v6/titles/${title.id}/quotes`, {
    params: {
      startDate,
      endDate
    }
  })
  const quotes: QuotesForTitlesQuotesInner[] = response.data.quotes

  const clearedQuotes: (QuotesForTitlesQuotesInner & {
    t: number,
    c: number
  })[] = quotes.filter(quote => quote.t !== undefined && quote.c !== undefined) as (QuotesForTitlesQuotesInner & {
    t: number,
    c: number
  })[]
  clearedQuotes.sort((a, b) => {
    return a.t - b.t
  })

  const dataSeries: (number | string)[][] = clearedQuotes.map(quote => [DateFormatter.formatDate(quote.t * 1000, 'YYYY-MM-DD'), quote.c])

  const minDataValue = Math.min(...dataSeries.map(dataPoint => dataPoint[1] as number))
  const maxDataValue = Math.max(...dataSeries.map(dataPoint => dataPoint[1] as number))

  let rounding = 1000
  while (maxDataValue - minDataValue < rounding) {
    rounding /= 10
  }

  return {
    titleId: title.id,
    titleName: title.name,
    chartDataSeries: dataSeries,
    minValue: Math.floor(minDataValue / rounding) * rounding,
    maxValue: Math.ceil(maxDataValue / rounding) * rounding,
    lastQuote: clearedQuotes.slice(-1)[0],
    secondLastQuote: clearedQuotes.slice(-2)[0],
    currency: title.currency || ''
  }
}

async function filterTitles (query: FilterSettingsList, limit: number, sortBy: string, partition?: string): Promise<any> {
  const params = {
    limit,
    sort_by: sortBy,
    partition
  }
  const response = await api.post('/v6/titles', query, {
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return response.data
}

export {
  api,
  axiosRequestManager,
  fetchTitleInfos,
  fetchTitleChartData,
  fetchInfosForTitles,
  fetchStatistics,
  fetchTopicsList,
  fetchChartData,
  fetchSolrMessages,
  fetchQuoteChartData,
  fetchSummary,
  filterTitles,
  extractReferencedPeople,
  getCoreToUse
}
