import { defineStore, storeToRefs } from 'pinia'
import { differenceInMilliseconds, Duration, endOfDay, startOfDay, sub } from 'date-fns'
import { computed, Ref, ref } from 'vue'
import { date } from 'quasar'
import { useAuthUserStore } from 'stores/auth-user-store'
import * as crypto from 'crypto-js'

export interface TimeSpan {
  name: string
  duration: Duration
  disabled?: boolean
}

export interface TimeRange {
    startDate: Date
    endDate: Date
}

const timeSpanBaseOptions: TimeSpan[] = [
  {
    name: 'Last 1 hour',
    duration: { hours: 1 }
  },
  {
    name: 'Last 3 hours',
    duration: { hours: 3 }
  },
  {
    name: 'Last 24 hours',
    duration: { hours: 24 }
  },
  {
    name: 'Last 3 days',
    duration: { days: 3 }
  },
  {
    name: 'Last 7 days',
    duration: { days: 7 }
  },
  {
    name: 'Last 30 days',
    duration: { days: 30 }
  },
  {
    name: 'Last 90 days',
    duration: { days: 90 },
    disabled: true
  },
  {
    name: 'Last 6 months',
    duration: { months: 6 },
    disabled: true
  },
  {
    name: 'Last 1 year',
    duration: { years: 1 },
    disabled: true
  }
]

interface SelectedTimeRange {
  startDate: Date | undefined,
  endDate: Date,
  selectedTimeSpan: TimeSpan | undefined
}

/**
 * TimeRageStore to globally set selected Time-Range
 */
export const timeRangeStore = defineStore('timeRange', () => {
  const userAuthStore = storeToRefs(useAuthUserStore())

  // Do not leak PII
  const extendedTimeRangeUserEmailHashes = [
    // jan
    'e163071c46d36728bff4b18c67d40ff2',
    // stefan
    '38746222391ffd5368276285da674b55',
    // owen jones
    '04e39f7def602b2a65d0eb68c3223ccb'
  ]

  const isExtendedTimeRangeAllowed = computed<boolean>(() => {
    return extendedTimeRangeUserEmailHashes.includes(`${crypto.MD5(userAuthStore.user.value.username)}`)
  })

  const getAllowedTimeRange = computed<number>(() => {
    return isExtendedTimeRangeAllowed.value ? 366 : 31
  })

  const timeSpanOptions = computed<TimeSpan[]>(() => {
    return timeSpanBaseOptions.map((timeSpan) => {
      if (isExtendedTimeRangeAllowed.value) {
        timeSpan.disabled = false
      }
      return timeSpan
    })
  })

  const selectedTimeRange : Ref<SelectedTimeRange> = ref({
    startDate: undefined,
    endDate: (new Date()),
    selectedTimeSpan: timeSpanBaseOptions[3]
  })
  function getCurrentStartDate () : Date {
    const selectedTimeSpan = selectedTimeRange.value.selectedTimeSpan
    if (selectedTimeSpan) {
      let startDate = sub(new Date(), selectedTimeSpan.duration)
      if (selectedTimeSpan.duration.days || selectedTimeSpan.duration.months || selectedTimeSpan.duration.years) {
        startDate = startOfDay(sub(selectedTimeRange.value.endDate, selectedTimeSpan.duration))
      }
      return startDate
    }
    // JLDEBUG DEFAULT??
    return selectedTimeRange.value.startDate ? selectedTimeRange.value.startDate : new Date()
  }

  function getCurrentEndDate () {
    return selectedTimeRange.value.endDate
  }

  function getCurrentExtendedStartDate () : Date {
    const selectedTimeSpan = selectedTimeRange.value.selectedTimeSpan

    // For a dynamic time period choose the next one as extended start date
    if (selectedTimeSpan) {
      const selectedTimeSpanIndex = timeSpanOptions.value.findIndex(timeSpan => timeSpan.name === selectedTimeSpan.name)
      const extendedTimeSpan = timeSpanOptions.value.at(selectedTimeSpanIndex + 1) || selectedTimeSpan
      let startDate = sub(new Date(), extendedTimeSpan.duration)
      if (extendedTimeSpan.duration.days || extendedTimeSpan.duration.months || extendedTimeSpan.duration.years) {
        startDate = startOfDay(sub(selectedTimeRange.value.endDate, extendedTimeSpan.duration))
      }
      return startDate
    }

    // For a fixed time period use the start date with doubled duration
    if (selectedTimeRange.value.endDate && selectedTimeRange.value.startDate) {
      const startTs = selectedTimeRange.value.startDate.getTime()
      const endTs = selectedTimeRange.value.endDate.getTime()
      // Calculated extended start date by subtracting duration of selected time period from startDate
      return new Date(startTs - (endTs - startTs))
    }

    return new Date()
  }

  function setSelectedTimeSpan (timeSpan: TimeSpan) {
    let now = endOfDay(new Date())
    let startDate = sub(now, timeSpan.duration)
    if (timeSpan.duration.days || timeSpan.duration.months || timeSpan.duration.years) {
      startDate = startOfDay(sub(now, timeSpan.duration))
    }

    if (timeSpan.duration.hours) {
      now = new Date()
      startDate = sub(now, timeSpan.duration)
    }

    selectedTimeRange.value = {
      startDate,
      endDate: now,
      selectedTimeSpan: timeSpan
    }
  }
  function setTimeRange (startDate: Date, endDate: Date) {
    selectedTimeRange.value = {
      startDate,
      endDate,
      selectedTimeSpan: undefined
    }
  }
  function getApiTimestamp (date : Date) {
    return (date.getTime() / 1000).toFixed(3)
  }
  function getPeriod (): string {
    return ''
    /* const formatter = new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short', timeStyle: 'short' })

      const start = formatter.format(this.getCurrentStartDate)
      const to = '' // JLTODO
      const end = formatter.format(this.getCurrentEndDate)

      return `${start} ${to} ${end}` */
  }

  function isIntradaySelection () {
    if (selectedTimeRange.value.selectedTimeSpan?.duration?.hours !== undefined) {
      return true
    }
    return differenceInMilliseconds(getCurrentEndDate(), getCurrentStartDate()) < 24 * 60 * 60 * 1000
  }

  function getTimeSpanOptionIndexByName (name: string) :number {
    return timeSpanOptions.value.findIndex(timeSpan => timeSpan.name === name)
  }

  function isTimeSpanSelected () : boolean {
    return selectedTimeRange.value.selectedTimeSpan !== undefined
  }

  function getSelectionLabel () {
    const currentSelection = selectedTimeRange.value
    if (currentSelection.selectedTimeSpan) {
      return currentSelection.selectedTimeSpan.name
    }
    if (!currentSelection.startDate) {
      return '-'
    }

    const startFormatted = date.formatDate(currentSelection.startDate, 'YYYY-MM-DD')
    const duration = (currentSelection.endDate.getTime() - currentSelection.startDate.getTime()) / 1000

    let range, symbol
    if (duration < 3600) {
      range = Math.round(duration / 60)
      symbol = 'm'
    } else if (duration < 22 * 3600) {
      range = Math.round(duration / (3600))
      symbol = 'h'
    } else if (duration < 28 * 24 * 3600) {
      range = Math.round(duration / (24 * 3600))
      symbol = 'd'
    } else {
      range = Math.round(duration / (30 * 24 * 3600))
      symbol = 'M'
    }
    return startFormatted + ' +' + range + symbol
  }

  return {
    selectedTimeRange,
    getSelectionLabel,
    getPeriod,
    getApiTimestamp,
    isTimeSpanSelected,
    setTimeRange,
    setSelectedTimeSpan,
    getCurrentEndDate,
    getCurrentStartDate,
    getCurrentExtendedStartDate,
    isIntradaySelection,
    getTimeSpanOptionIndexByName,
    timeSpanOptions,
    isExtendedTimeRangeAllowed,
    getAllowedTimeRange
  }
})
