import { getTodaysTimestamp } from './DataTransformer'

export enum ReportModelType {
    DAILY = 'daily',
    REALTIME = 'realtime'
}

export interface ReportModel {
    id: number
    type: ReportModelType
    name: string
}

interface SourceReportModels {
    realtime: ReportModel
    daily: ReportModel
}

export const DEFAULT_REPORT_MODELS = {
  realtime: { id: 2, type: ReportModelType.REALTIME, name: '10minute' },
  daily: { id: 4, type: ReportModelType.DAILY, name: 'daily_5am' }
}

const sourceReportModels: Map<string, SourceReportModels> = new Map()
sourceReportModels.set('', DEFAULT_REPORT_MODELS)
sourceReportModels.set('www.reddit.com', {
  realtime: { id: 202, type: ReportModelType.REALTIME, name: '10minute_reddit' },
  daily: { id: 204, type: ReportModelType.DAILY, name: 'daily_5am_reddit' }
})
sourceReportModels.set('twitter.com', {
  realtime: { id: 214, type: ReportModelType.REALTIME, name: '10minute_twitter' },
  daily: { id: 215, type: ReportModelType.DAILY, name: 'daily_5am_twitter' }
})
sourceReportModels.set('tiktok.com', {
  realtime: { id: 237, type: ReportModelType.REALTIME, name: '10minute_tiktok' },
  daily: { id: 222, type: ReportModelType.DAILY, name: 'daily_5am_tiktok' }
})
sourceReportModels.set('stocktwits.com', {
  realtime: { id: 206, type: ReportModelType.REALTIME, name: '10minute_stocktwits' },
  daily: { id: 207, type: ReportModelType.DAILY, name: 'daily_5am_stocktwits' }
})
sourceReportModels.set('telegram.org', {
  realtime: { id: 239, type: ReportModelType.REALTIME, name: '10minute_telegram' },
  daily: { id: 220, type: ReportModelType.DAILY, name: 'daily_5am_telegram' }
})
sourceReportModels.set('discord.com', {
  realtime: { id: 217, type: ReportModelType.REALTIME, name: '10minute_discord' },
  daily: { id: 216, type: ReportModelType.DAILY, name: 'daily_5am_discord' }
})
sourceReportModels.set('xueqiu.com', {
  realtime: { id: 212, type: ReportModelType.REALTIME, name: '10minute_xueqiu' },
  daily: { id: 213, type: ReportModelType.DAILY, name: 'daily_5am_xueqiu' }
})
sourceReportModels.set('boards.4channel.org', {
  realtime: { id: 236, type: ReportModelType.REALTIME, name: '10minute_4chan' },
  daily: { id: 228, type: ReportModelType.DAILY, name: 'daily_5am_4chan' }
})
sourceReportModels.set('www.investing.com', {
  realtime: { id: 210, type: ReportModelType.REALTIME, name: '10minute_investing' },
  daily: { id: 211, type: ReportModelType.DAILY, name: 'daily_5am_investing' }
})
sourceReportModels.set('www.weibo.com', {
  realtime: { id: 238, type: ReportModelType.REALTIME, name: '10minute_weibo' },
  daily: { id: 232, type: ReportModelType.DAILY, name: 'daily_5am_weibo' }
})

export function getReportModel (source: string, startDate: Date, endDate: Date, isExtendedTimeRange = true): ReportModel {
  const limitForReportModel10min = (isExtendedTimeRange ? 31 : 8) * 24 * 3600 * 1000

  const timeInterval = (getTodaysTimestamp(endDate.getTime()) - getTodaysTimestamp(startDate.getTime())) / 1000
  const reportModels = sourceReportModels.get(source) || DEFAULT_REPORT_MODELS

  return timeInterval <= limitForReportModel10min ? reportModels.realtime : reportModels.daily
}

export function getDefaultReportModel (startDate: Date, endDate: Date, isExtendedTimeRange = true): ReportModel {
  return getReportModel('', startDate, endDate, isExtendedTimeRange)
}
