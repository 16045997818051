import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface Source {
  label: string,
  value: string,
  icon?: string
}

const sourceOptions: Source[] = [
  {
    label: 'All',
    value: '',
    icon: 'spi-globe'
  },
  {
    label: 'Reddit',
    value: 'www.reddit.com',
    icon: 'spi-reddit'
  },
  {
    label: 'X/Twitter',
    value: 'twitter.com',
    icon: 'spi-x-twitter'
  },
  {
    label: 'TikTok',
    value: 'tiktok.com',
    icon: 'spi-tiktok'
  },
  {
    label: 'Stocktwits',
    value: 'stocktwits.com',
    icon: 'spi-stocktwits'
  },
  {
    label: 'Telegram',
    value: 'telegram.org',
    icon: 'spi-telegram'
  },
  {
    label: 'Discord',
    value: 'discord.com',
    icon: 'spi-discord'
  },
  {
    label: 'Xueqiu',
    value: 'xueqiu.com',
    icon: 'spi-xueqiu'
  },
  {
    label: '4Chan',
    value: 'boards.4channel.org',
    icon: 'spi-4chan'
  },
  {
    label: 'Investing',
    value: 'www.investing.com',
    icon: 'spi-globe'
  },
  {
    label: 'Weibo',
    value: 'www.weibo.com',
    icon: 'spi-weibo'
  }
]

/**
 * SourceFilterStore to globally set selected source
 */
export const sourceFilterStore = defineStore('sourceFilter', () => {
  const activeSourceValue = ref(sourceOptions[0].value)

  const activeSource = computed(() => {
    return sourceOptions.find(option => option.value === activeSourceValue.value)
  })

  return {
    activeSource,
    activeSourceValue,
    sourceOptions
  }
})
