import { Ref, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { webSocketStore } from 'stores/web-socket-store'
import { TitlesBuzzSentiment } from 'boot/axios'
import { BuzzSentiment } from '@stockpulse/typescript-axios/types/buzz-sentiment'
import { Auxiliary, TitlesBuzzSentimentDataCollection } from 'src/helper/Auxiliary'
import { useBufferedThrottleFn } from 'src/helper/BufferedThrottle'
import { Tick as TickDto } from '@stockpulse/typescript-axios'

export type SubscriptionId = string

export interface WsSubscription {
  titleIds:number[],
  subscriptionId: SubscriptionId
}

export type Tick = { titleId: number, t: number, value: number }

export interface TitleTickCollection {
    [key: number]: Tick
}

export const useRealTimeDataStore = defineStore('realTimeDataStore', () => {
  const webSocket = webSocketStore()
  const { isSocketReady } = storeToRefs(webSocket)

  const titlesRealtimeData: Ref<TitlesBuzzSentimentDataCollection> = ref({})
  const pendingBuzzSentimentSubscriptions : Ref<WsSubscription[]> = ref([])
  const currentBuzzSentimentSubscriptions : Ref<WsSubscription[]> = ref([])

  const titlesTick: Ref<TitleTickCollection> = ref({})
  const pendingTickSubscriptions : Ref<WsSubscription[]> = ref([])
  const currentTickSubscriptions : Ref<WsSubscription[]> = ref([])

  const onWebsocketOpen = (isOpen: boolean) => {
    if (isOpen) {
      emitSubscribeToUnprocessedTitles()
    }
  }
  watch(isSocketReady, onWebsocketOpen, { immediate: true })

  const bufferedTitleBuzzSentimentUpdate = (newValues: BuzzSentiment[]) => {
    const reducedBuffer = newValues.reduce((acc: TitlesBuzzSentiment, curr : BuzzSentiment) : TitlesBuzzSentiment => {
      acc[curr.id] = curr
      return acc
    }, {} as TitlesBuzzSentiment)

    const workingValues = titlesRealtimeData.value

    for (const entryKey of Object.keys(reducedBuffer).map(Number)) {
      const buzzSentiment = reducedBuffer[entryKey]
      const sentimentScore = Auxiliary.calculateSentimentScore(buzzSentiment.s || 0) / 100
      const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
      const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)

      workingValues[entryKey] = {
        id: buzzSentiment.id,
        buzz: buzzSentiment.b,
        sentiment: buzzSentiment.s || 0,
        sentimentIcon,
        sentimentIconColor,
        sentimentScore,
        time: buzzSentiment.t
      }
    }
    titlesRealtimeData.value = workingValues
  }

  const throttleUpdateTitleBuzzSentiment = useBufferedThrottleFn(bufferedTitleBuzzSentimentUpdate, 3000)

  function emitSubscribeToUnprocessedTitles () {
    if (pendingBuzzSentimentSubscriptions.value.length > 0) {
      pendingBuzzSentimentSubscriptions.value.forEach(add => subscribeTitleUpdates(add.titleIds))
      pendingBuzzSentimentSubscriptions.value = []
    }

    if (pendingTickSubscriptions.value.length > 0) {
      pendingTickSubscriptions.value.forEach(add => subscribeTitleTicks(add.titleIds))
      pendingTickSubscriptions.value = []
    }
  }

  function subscribeTitleUpdates (titleIds: number[]): SubscriptionId {
    const subscriptionId = generateSubscriptionId()
    if (!isSocketReady.value) {
      pendingBuzzSentimentSubscriptions.value.push({ titleIds, subscriptionId })
      return subscriptionId
    }

    webSocket.emitSubscribeTitles(titleIds, handleWebsocketTitleUpdateMessage, websocketSubscribeTitleIdsCallback)

    currentBuzzSentimentSubscriptions.value.push({ titleIds, subscriptionId })
    return subscriptionId
  }

  function subscribeTitleTicks (titleIds: number[]): SubscriptionId {
    unsubscribeTitlesTicks(currentTickSubscriptions.value.map(subscription => subscription.subscriptionId))

    const subscriptionId = generateSubscriptionId()
    if (!isSocketReady.value) {
      pendingTickSubscriptions.value.push({ titleIds, subscriptionId })
      return subscriptionId
    }

    webSocket.emitSubscribeTitlesTicks(titleIds, handleWebsocketTitlesTicks, websocketSubscribeTitlesTicksCallback)
    currentTickSubscriptions.value.push({ titleIds, subscriptionId })

    return subscriptionId
  }

  function generateSubscriptionId (): SubscriptionId {
    while (true) {
      const timestamp = new Date().getTime()
      const randomNum = Math.floor(Math.random() * 1000000)
      const subscriptionId = `${timestamp}-${randomNum}`
      const found = currentBuzzSentimentSubscriptions.value.findIndex(sub => sub.subscriptionId === subscriptionId) !== -1 ||
        pendingBuzzSentimentSubscriptions.value.findIndex(sub => sub.subscriptionId === subscriptionId) !== -1
      if (!found) {
        return subscriptionId
      }
    }
  }

  function unsubscribeTitleUpdates (subscriptionIds: SubscriptionId[]) {
    subscriptionIds.forEach(subscriptionId => {
      const wsPendingSubscriptionIndex = pendingBuzzSentimentSubscriptions.value.findIndex(ws => ws.subscriptionId === subscriptionId)
      if (wsPendingSubscriptionIndex !== -1) {
        pendingBuzzSentimentSubscriptions.value.slice(wsPendingSubscriptionIndex, 1)
      }

      const wsSubscriptionIndex = currentBuzzSentimentSubscriptions.value.findIndex(ws => ws.subscriptionId === subscriptionId)
      if (wsSubscriptionIndex === -1) {
        return
      }
      const currentTitleIds = getUniqueTitleIds(currentBuzzSentimentSubscriptions.value)

      currentBuzzSentimentSubscriptions.value.splice(wsSubscriptionIndex, 1)

      const remainingTitleIds = getUniqueTitleIds(currentBuzzSentimentSubscriptions.value)

      const titleIdsToUnsubscribe = currentTitleIds.filter(curTitle => !remainingTitleIds.includes(curTitle))

      webSocket.emitUnsubscribeTitles(titleIdsToUnsubscribe)
    })
  }

  function unsubscribeTitlesTicks (subscriptionIds: SubscriptionId[]) {
    subscriptionIds.forEach(subscriptionId => {
      const wsPendingSubscriptionIndex = pendingTickSubscriptions.value.findIndex(ws => ws.subscriptionId === subscriptionId)
      if (wsPendingSubscriptionIndex !== -1) {
        pendingTickSubscriptions.value.slice(wsPendingSubscriptionIndex, 1)
      }

      const wsSubscriptionIndex = currentTickSubscriptions.value.findIndex(ws => ws.subscriptionId === subscriptionId)
      if (wsSubscriptionIndex === -1) {
        return
      }
      const currentTitleIds = getUniqueTitleIds(currentTickSubscriptions.value)

      currentTickSubscriptions.value.splice(wsSubscriptionIndex, 1)

      const remainingTitleIds = getUniqueTitleIds(currentTickSubscriptions.value)

      const titleIdsToUnsubscribe = currentTitleIds.filter(curTitle => !remainingTitleIds.includes(curTitle))

      webSocket.emitUnsubscribeTitles(titleIdsToUnsubscribe)
    })
  }

  function getUniqueTitleIds (wsRequests: WsSubscription[]): number[] {
    const uniqueIdsSet = new Set<number>()
    wsRequests.forEach(wsRequest => {
      wsRequest.titleIds.forEach(id => uniqueIdsSet.add(id))
    })
    return Array.from(uniqueIdsSet)
  }

  function websocketSubscribeTitleIdsCallback (error: unknown, data: unknown): void {
    if (error) {
      return
    }
    let dataArray : Array<BuzzSentiment>
    if (!Array.isArray(data)) {
      dataArray = [data as BuzzSentiment]
    } else {
      dataArray = data as BuzzSentiment[]
    }

    throttleUpdateTitleBuzzSentiment(dataArray)
  }

  function websocketSubscribeTitlesTicksCallback (error: unknown, data: unknown): void {
    if (error) {
      return
    }

    const tickArray: Array<TickDto> = !Array.isArray(data) ? [data as TickDto] : data as TickDto[]
    tickArray.forEach(tick => {
      titlesTick.value[tick.id] = {
        titleId: tick.id,
        value: tick.l,
        t: tick.t * 1000
      }
    })
  }

  function handleWebsocketTitleUpdateMessage (data: unknown, error: unknown): void {
    if (error) {
      return
    }
    throttleUpdateTitleBuzzSentiment([data as BuzzSentiment])
  }

  function handleWebsocketTitlesTicks (data: unknown, error: unknown): void {
    if (error || data === null) {
      return
    }

    const tickArray: TickDto[] = !Array.isArray(data) ? [data as TickDto] : data as TickDto[]
    tickArray.forEach(tick => {
      titlesTick.value[tick.id] = {
        titleId: tick.id,
        value: tick.l,
        t: tick.t * 1000
      }
    })
  }

  return {
    titlesRealtimeData,
    titlesTick,
    subscribeTitleUpdates,
    unsubscribeTitleUpdates,
    subscribeTitleTicks,
    unsubscribeTitlesTicks
  }
})
